<template>
  <div id="MyCourse-box" style="height: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MyCourse-box',
};
</script>

<style></style>
